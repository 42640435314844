'use strict'

BREAK_POINT = 768
VIEW_WIDTH = 1100

do ->
	w = document.documentElement.clientWidth
	if BREAK_POINT <= w
		vp = document.querySelector '[name="viewport"]'
		vp.content = "width=#{VIEW_WIDTH}, initial-scale=1" if vp

$ ->

	$win = $ window
	$body = $ 'body'
	$html = $ 'html'

	$('a, area').bcScrollTo()
	$('a[href], button')
		.not('.bgt-btn')
		# .filter(-> !$(@).closest('.home-content--product').length)
		# .filter(-> !$(@).closest('.home-content--works').length)
		# .filter(-> !$(@).closest('.home-content--other').length)
		.filter(-> !$(@).closest('.index-list').length)
		.filter(-> !$(@).closest('.nav-local').length)
		.filter(-> !$(@).closest('.footer').length)
		.bcWink()
	$('.index-list > *').bcWink()

	##
	# トップスライドショー
	#
	##
	$slideshow = $('[data-psyborg="slideshow"]')
	if $slideshow.length
		$slideshow.bcImageLoaded ->
			$slideshow.psycle
				transition: 'fade'
				delay: 4000
				duration: 1200
				resizable: on
			$slideshow.find('li a, li span').bcBackground outer: on
			p = $slideshow.data 'psycle'
			# p.trigger $.PsycleReflowTiming.RESIZE_END
			id = $slideshow.attr('data-id');
			if not /[a-z-_][a-z0-9-_]*/ig.test id
				window.console?.warn? '"data-id" attribute value is invalid.'
				return
			$ctrl = $('[data-psyborg="carousel-ctrl"][data-control="' + id + '"]')
			if $ctrl.length
				p.ctrl $ctrl,
					prev: '[data-psyborg="carousel-ctrl-prev"]'
					next: '[data-psyborg="carousel-ctrl-next"]'
			$slideshow.find('.link-box__thumb, .link-line__thumb').bcImageLoaded ->
				$(@).bcBackground outer: on
			$marker = $('[data-psyborg="carousel-marker"][data-for="' + id + '"]')
			if $marker.length
				p.marker().appendTo $marker


	##
	# カルーセル
	# ※呼び出しは背景画像化が完了してから
	##
	$('[data-psyborg="carousel"]').bcImageLoaded ->
		$crsl = $ @
		$crsl.psycle
			transition: 'slide'
			repeat: 'loop'
			auto: off
			resizable: on
			draggable: on
			swipeable: on
		p = $crsl.data 'psycle'
		id = $crsl.attr('data-id');
		if not /[a-z-_][a-z0-9-_]*/ig.test id
			window.console?.warn? '"data-id" attribute value is invalid.'
			return
		$ctrl = $('[data-psyborg="carousel-ctrl"][data-control="' + id + '"]')
		if $ctrl.length
			p.ctrl $ctrl,
				prev: '[data-psyborg="carousel-ctrl-prev"]'
				next: '[data-psyborg="carousel-ctrl-next"]'
		$crsl.find('.link-box__thumb, .link-line__thumb').bcImageLoaded ->
			$(@).bcBackground outer: on
		$marker = $('[data-psyborg="carousel-marker"][data-for="' + id + '"]')
		if $marker.length
			p.marker().appendTo $marker

		$crsl.bcBoxAlignHeight(Infinity, '.post-info');
		return

	##
	# 固定ヘッダ
	#
	##
	# if $body.hasClass 'page-home'
	# 	$header = $ '.header'
	# 	$win.on 'resize scroll', ->
	# 		`
	# 		var y = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
	# 		`
	# 		height = $header.height?()
	# 		if height and (window.document.documentElement.clientHeight - height) < y
	# 			$html.addClass 'js-nav-fixed'
	# 		else
	# 			$html.removeClass 'js-nav-fixed'
	# 		return
	# 	# oneScrollTo = ->
	# 	# 	$header.off 'mouseenter', oneScrollTo
	# 	# 	$.bcScrollTo $ '.header'
	# 	# 	return
	# 	# $header.on 'mouseenter', oneScrollTo

	##
	# メガドロップななめのやつ
	#
	##
	# $.fn._mega = (opt) ->
	#
	# 	SVG_NS = 'http://www.w3.org/2000/svg'
	#
	# 	timer = 0
	# 	$target = null
	#
	# 	config = $.extend
	# 		delay: 200
	# 		trigger: '>a'
	# 		subMenuBase: '>ul'
	# 		subMenuArea: '>ul >*:first-child'
	# 		vector: 'to bottom'
	# 	,
	# 		opt
	#
	# 	createSVGElement = (tag) ->
	# 		return document.createElementNS SVG_NS, tag
	#
	# 	hitArea = createSVGElement 'svg'
	# 	hitAreaPolygon = createSVGElement 'polygon'
	# 	hitAreaPolygon.setAttribute 'fill', 'transparent'
	# 	# hitAreaPolygon.setAttribute 'fill', 'rgba(255, 0, 0, 0.4)'
	# 	hitAreaPolygon.setAttribute 'style', 'cursor: pointer;'
	# 	hitArea.appendChild hitAreaPolygon
	#
	# 	getRect = ($el) ->
	# 		$el[0].getBoundingClientRect()
	#
	# 	enter = (e) ->
	# 		$this = $ @
	# 		$target = $this.find config.trigger
	# 		unless $target.length then return
	# 		$subMenuBase = $this.find config.subMenuBase
	# 		subMenuBase = $subMenuBase[0]
	# 		unless $subMenuBase.length then return
	# 		$subMenuArea = $this.find config.subMenuArea
	# 		unless $subMenuArea.length then return
	# 		targetRect = getRect $target
	# 		subMenuBaseRect = getRect $subMenuBase
	# 		subMenuAreaRect = getRect $subMenuArea
	# 		switch config.vector
	# 			when 'to bottom'
	# 				points = [
	# 					# [targetRect.left, 0]
	# 					# [targetRect.left + targetRect.width, 0]
	# 					[e.clientX, e.clientY]
	# 					[subMenuAreaRect.left + subMenuAreaRect.width, targetRect.height]
	# 					[subMenuAreaRect.left, targetRect.height]
	# 				]
	# 				hitArea.setAttribute 'width', subMenuBaseRect.width
	# 				hitArea.setAttribute 'height', targetRect.height
	# 				hitArea.setAttribute 'style', "position: absolute; top: #{targetRect.height * -1}px; left: 0;"
	# 			when 'to right'
	# 				points = [
	# 					[0, targetRect.top - subMenuAreaRect.top]
	# 					[targetRect.width, 0]
	# 					[targetRect.width, subMenuAreaRect.height]
	# 					[0, targetRect.top - subMenuAreaRect.top + targetRect.height]
	# 				]
	# 				hitArea.setAttribute 'width', targetRect.width
	# 				hitArea.setAttribute 'height', subMenuBaseRect.height
	# 				hitArea.setAttribute 'style', "position: absolute; top: 0px; left: #{targetRect.width * -1}px;"
	# 		hitAreaPolygon.setAttribute 'points', points.join()
	# 		subMenuBase.appendChild hitArea
	# 		countdown()
	# 		return
	#
	# 	countdown = ->
	# 		clearTimeout timer
	# 		timer = setTimeout remove, config.delay
	# 		return
	#
	# 	remove = ->
	# 		clearTimeout timer
	# 		hitArea.parentNode?.removeChild hitArea
	# 		return
	#
	# 	click = ->
	# 		if $target and $target[0].href
	# 			location.href = $target[0].href
	# 		return
	#
	# 	@on 'mouseenter', enter
	# 	# @on 'mousedown', (e) -> console.log e
	#
	# 	hitAreaPolygon.addEventListener 'mousemove', countdown, false
	# 	hitAreaPolygon.addEventListener 'mouseout', remove, false
	# 	hitArea.addEventListener 'mouseup', click, false
	# 	return @

	##
	# メガドロップ
	#
	##
	# $('.nav-global__item')._mega
	# 	subMenuBase: '.mega-dropdown'
	# 	subMenuArea: '.mega-dropdown__inner'
	# 	vector: 'to bottom'
	# $('.nav-global__item [data-sitemap="page-category"]')._mega
	# 	subMenuArea: '>ul'
	# 	vector: 'to right'
	# $('.nav-global__item').on 'mouseenter', ->
	# 	$mdd = $(@).find('.mega-dropdown')
	# 	$list = $mdd.find('.mega-dropdown__menu-list ul li')
	# 	if $mdd.length and $list.length
	# 		$body.addClass 'is-nav-hovered'
	# 	else
	# 		$body.removeClass 'is-nav-hovered'
	# 	return
	# .on 'mouseleave', ->
	# 	$body.removeClass 'is-nav-hovered'
	# 	return

	##
	# スマホグロナビ
	#
	##
	$('[data-toggle-for]').each ->
		DUR = 500
		$toggle = $ @
		id = $toggle.data 'toggleFor'
		statusKeyName = "data-toggle-status-#{id}"
		if id
			try
				$target = $ "##{id}"
				$toggle.on 'click', ->
					if 'open' is $body.attr statusKeyName
						$target.stop(on, on).slideUp DUR, ->
							$body.attr statusKeyName, 'close'
					else
						$target.stop(on, on).slideDown DUR
						$body.attr statusKeyName, 'open'
					return false
			catch err
				# err

	# ボックスリンク化
	$('.index-list > *').bcBoxLink()

	##
	# 高さ揃え
	#
	##
	alignOption = {}
	alignOption.defaults = 3
	alignOption[BREAK_POINT] = 1
	$('.content-group--page-post .link-box .post-info__title').bcBoxAlignHeight alignOption
	$('.content-group--page-post .link-box .post-info__description').bcBoxAlignHeight alignOption
	$('.content-group--page-post .link-box .post-info').bcBoxAlignHeight alignOption

	indexListAlignOption = {}
	indexListAlignOption.defaults = 3
	indexListAlignOption[BREAK_POINT] = 1
	$('.index-list').bcBoxAlignHeight indexListAlignOption, '.bge-ckeditor h4'
	$('.index-list').bcBoxAlignHeight indexListAlignOption, '.bge-ckeditor h4 + p'


	##
	# 背景画像化
	#
	##
	$('.link-box__thumb, .link-line__thumb')
	.filter -> !$(@).closest('[data-psyborg="carousel"]').length
	.filter -> !$(@).closest('.home-content--concept').length
	.bcImageLoaded ->
		$(@).bcBackground outer: on

	##
	# マップ
	#
	##
	$('[data-baser="Maps"]').bcMaps({styles: [{featureType: "all", elementType: "all", stylers: [{saturation: -100}]}]})

	##
	# フッターサイトマップ
	# flexを推奨 bcSplitListは非推奨
	##
	# $footerSiteMap = $('.footer__sitemap.nav-sitemap')
	# footerSiteMapCols = +$footerSiteMap.data('listCols') || 1;
	# $footerSiteMap.bcSplitList footerSiteMapCols, splitChildren: no


	##
	# ブロックエフェクト
	#
	##
	if $.fn.sight
		$blocks = $('[data-bgb]').sight()
		$blocks.on 'sightenter sightshow', ->
			$(@).addClass 'js-sight'

	# ローカルナビ
	$lnav = $('.nav-local__current')
	if $lnav.length
		$lnav.on 'click', ->
			$this = $ @
			$nav = $this.next()
			$nav.slideToggle()
			$this.toggleClass 'nav-local__current--open'

	##
	# 固定バナーをフッター直前で止める
	#
	##
	$footer = $('.footer')
	$navSide = $('.nav-side')
	$header = $('.header')
	fixedBannerTopOffset = 246
	fixedBannerFooterOffset = 100
	bodyBorder = 8
	fixedBannerOnScroll = ->
		nHite = $navSide.outerHeight()
		hTop = $header[0].offsetTop
		fTop = $footer[0].offsetTop
		nY = window.scrollY + nHite + fixedBannerTopOffset + fixedBannerFooterOffset
		if fTop < nY
			$navSide.css
				position: 'absolute'
				top: fTop - hTop - nHite - fixedBannerFooterOffset
				right: -bodyBorder # border-width分
		else
			$navSide.removeAttr 'style'
		return
	$(window).on 'scroll', ->
		if 'requestAnimationFrame' in window
			requestAnimationFrame fixedBannerOnScroll
		else
			fixedBannerOnScroll()
		return
	##
	# 画像の右クリック禁止
	#
	#
	# $(document).on 'contextmenu', 'img', (e) ->
	# 	return false
